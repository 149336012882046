import React, {useEffect} from 'react';
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { isLoggedIn, logout } from "../../features/user";
import { useTranslation } from 'react-i18next';

import Frame from '../widgets/Frame';

export function ComingSoon() {
    const { t } = useTranslation();

    return (
            <Frame>
                <div className='main main-congratulations'>
                    <div className='main-top'>
                        <h1>{t("The site is coming soon!")}</h1>
                    </div>
                    <div className='main-body'>
                        <div className='right-main'>
                            <div className='right-main-inner'>
                                <h2>{t("Our site is coming soon - stay tuned.")}</h2>
                            </div>
                        </div>    
                        <div className='left-main'>
                            <img src='/images/person-paper-plane.png' className='content-logo' alt=''/>
                        </div>
                    </div>
                </div>
            </Frame>
    );
}