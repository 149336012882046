import { Navigate, Outlet } from 'react-router-dom';

const RedirectToComingSoon = ({}) => {
    if(process.env.REACT_APP_IS_TEST_MODE === "true"){
        return <Outlet />;
    }
    return <Navigate to="/coming-soon" />;
};


export default RedirectToComingSoon;