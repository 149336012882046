import {React, useEffect} from 'react';
import { BrowserRouter as Router, Routes as Switch, Route } from 'react-router-dom';
import "./styles.css";
import {Login} from './components/pages/Login';
import {LoginSimCustomers} from './components/pages/LoginSimCustomers';
import {Start} from './components/pages/Start';
import {LoginVerify} from './components/pages/LoginVerify';
import {VerifyEmail} from './components/pages/VerifyEmail';
import {VerifyPhone} from './components/pages/VerifyPhone';
import {ResetPassword} from './components/pages/ResetPassword';
import {RestoreUsername} from './components/pages/RestoreUsername';
import {CreatePassword} from './components/pages/CreatePassword';
import {PasswordReset} from './components/pages/PasswordReset';
import {Verification2FACodeType} from './components/pages/Verification2FACodeType';
import {VerificationPhoneCodeType} from './components/pages/VerificationPhoneCodeType';
import {SuccessfullyLoggedIn} from './components/pages/SuccessfullyLoggedIn';
import {Register} from './components/pages/Register';
import {CreateCredentails} from './components/pages/CreateCredentials';
import {TwoStepVerification} from './components/pages/TwoStepVerification';
import {Congratulations} from './components/pages/Congratulations';
import RedirectByReseller from './components/routes/RedirectByReseller';
import AllowSimLoginByReseller from './components/routes/AllowSimLoginByReseller';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from "react-redux";
import {Dialog, DialogTitle, DialogContent, DialogActions, Typography, Button} from '@mui/material';
import { hideErrorDialog } from "./features/errorDialog";
import { fetchReseller } from "./features/reseller";
import HanldeURLParameters from './components/routes/HandleUrlParameters';
import { ComingSoon } from './components/pages/ComingSoon';
import RedirectToComingSoon from './components/routes/RedirectToComingSoon';

const updateResellerThemeItems = (resellerPublicPath) => {
  const linksToUpdate = [
    {
      selector: "link[rel*='icon']",
      href: resellerPublicPath + '/favicon.ico'
    },
    {
      selector: "link[rel*='apple-touch-icon']",
      href: resellerPublicPath + '/logo192.png'
    },
    {
      selector: "link[rel*='manifest']",
      href: resellerPublicPath + '/manifest.json'
    },
  ];
  let linkElement = null;
  for(const linkItem of linksToUpdate){
    linkElement = document.querySelector(linkItem.selector);
    linkElement.href = linkItem.href;
  }
};

function App() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const errorDialog = useSelector((state) => state.errorDialog.value);
  const { value: resellerData, status } = useSelector((state) => state.reseller);

  useEffect(() => {
    if (!resellerData && status === "idle") {
      dispatch(fetchReseller());
    }
  }, [dispatch, resellerData, status]);

  useEffect(() => {
    if (resellerData?.public_path) {
      updateResellerThemeItems(resellerData.public_path);
    }
  }, [resellerData]);

  const handleErrorDialogClose = () => {
    dispatch(hideErrorDialog());
  };

  return (
    <>
      <Router>
        <Switch> 
            <Route path='/' element={<HanldeURLParameters />}>
              <Route path='/' element={<RedirectByReseller />}>
                <Route path='/' element={<RedirectToComingSoon />}>
                  <Route path='/' element={<Start />} />
                  <Route path='/start' element={<Start />} />
                  <Route path='/login' element={<Login />} />
                  <Route path='/restore-username' element={<RestoreUsername />} />
                  <Route path='/reset-password' element={<ResetPassword />} />
                  <Route path='/create-password' element={<CreatePassword />} />
                  <Route path='/password-reset' element={<PasswordReset />} />
                  <Route path='/login-verify' element={<LoginVerify />} />
                  <Route path='/verification-2fa-code-type' element={<Verification2FACodeType />} />
                  <Route path='/successfully-logged-in' element={<SuccessfullyLoggedIn />} />

                  <Route path='/register' element={<Register />} />
                  <Route path='/verify-email' element={<VerifyEmail />} />
                  <Route path='/verify-phone' element={<VerifyPhone />} />
                  <Route path='/verification-phone-code-type' element={<VerificationPhoneCodeType />} />
                  <Route path='/create-credentials' element={<CreateCredentails />} />
                  <Route path='/two-step-verification' element={<TwoStepVerification />} />
                  <Route path='/congratulations' element={<Congratulations />} />
                </Route>
                <Route path='/coming-soon' element={<ComingSoon />} />
              </Route>
              <Route path='/' element={<AllowSimLoginByReseller />}>
                <Route path='/login-sim-customers' element={<LoginSimCustomers />} />
              </Route>
            </Route>
        </Switch>
      </Router>
      <Dialog open={errorDialog.isOpen} onClose={handleErrorDialogClose}>
        <DialogTitle>{t(errorDialog.title)}</DialogTitle>
        <DialogContent>
        <Typography component="div">{t(errorDialog.text)}</Typography>
        </DialogContent>
        <DialogActions> 
          <Button  variant="contained"onClick={handleErrorDialogClose} size="large">{t('Close')}</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default App;
